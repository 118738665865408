import React from "react"

import { initialState } from "./AppContext"
import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "./services/context"
import { isObjectEmpty } from "../services/general"

const SAVE_DOCUMENTS = "SAVE_DOCUMENTS"
const SAVE_PRESCRIPTION_FILES = "SAVE_PRESCRIPTION_FILES"
const RESET_DETAILS = "RESET_DETAILS"
const SHOW_MODAL = "SHOW_MODAL"
const HIDE_MODAL = "HIDE_MODAL"
const SHOW_TOAST = "SHOW_TOAST"
const HIDE_TOAST = "HIDE_TOAST"
const SHOW_DISCLAIMER = "SHOW_DISCLAIMER"
const HIDE_DISCLAIMER = "HIDE_DISCLAIMER"
const SAVE_CONTEXT_TO_SESSION = "SAVE_CONTEXT_TO_SESSION"
const GET_CONTEXT_FROM_SESSION = "GET_CONTEXT_FROM_SESSION"
const REMOVE_CONTEXT_FROM_SESSION = "REMOVE_CONTEXT_FROM_SESSION"
const SET_WEBSITE_NAME = "SET_WEBSITE_NAME"

export let AppReducer = (state, action) => {
  switch (action.type) {
    case SAVE_PRESCRIPTION_FILES:
      return {
        ...state,
        orderForm: {
          ...state.orderForm,
          prescriptionFiles: action.payload,
        },
      }

    case SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case SHOW_DISCLAIMER:
      return { ...state, disclaimer: { ...initialState.disclaimer } }

    case HIDE_DISCLAIMER:
      return { ...state, disclaimer: { ...action.payload, isActive: false } }

    case RESET_DETAILS:
      return { ...initialState }

    case SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved

    case GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }

      return {
        ...returnState,
        modal: {
          ...initialState.modal,
        },
      }

    case REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }

    case SET_WEBSITE_NAME:
      return { ...state, websiteName: action.payload }

    default:
      return
  }
}
